import { app } from 'o365.modules.configs.ts';

let connection = null;
if(window.localStorage.getItem('devtools.hmr') === 'true'){
    connection = new signalR.HubConnectionBuilder()
    .withUrl("/api/dev/livereload")
    .withAutomaticReconnect()
    .build();

    connection.start().then(_ => {
        connection.invoke("JoinGroup", `${app.id}-view`, false);
        console.log('Live Reload Enabled. Listening for changes...');
    });
}

export class HMRManager {
    id;
    isAccepted = false;
    acceptCallbacks = [];
    disposeCallbacks = [];
    module;

    constructor(id, module) {
        this.id = id;
        this.module = module;
    }

    dispose(callback) {
        this.disposeCallbacks.push(callback);
    }

    accept(callback) {
        if (!this.isAccepted) {
            this.isAccepted = true;
            if(connection !== null){
                connection.on("moduleUpdated", (moduleFingerPrint) => {
                    console.log(`Module updated: ${moduleFingerPrint}`);
                    this.applyUpdate(moduleFingerPrint);
                });
            }
        }
        if (callback === true) {
            callback = () => { };
        }
        this.acceptCallbacks.push({
            callback
        });
    }

    async applyUpdate(moduleFingerPrint) {
        const acceptCallbacks = this.acceptCallbacks;
        const disposeCallbacks = this.disposeCallbacks;
        this.disposeCallbacks = [];

        disposeCallbacks.map((callback) => callback());
        for (const { callback: acceptCallback } of acceptCallbacks) {
            const [module] = await Promise.all([
                import(`/scripts/apps/${moduleFingerPrint}`),
            ]);
            acceptCallback({ module });
        }
        return true;
    }
}





